<template>
  <div>
    <portal to="title-page">
      <h1>Monedas y tipo de cambio</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn @click="$router.push({path:'/backend/admin/currencies/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Moneda</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2"
          @click="$router.push({path:`/backend/admin/currencies/${item.id}/edit`})"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn @click="setItem(item)" :disabled="item.code.toLowerCase() == 'usd'"
          small :color="item.status == 'active' ? 'green' : 'red'"
          :dark="!(item.code.toLowerCase() == 'usd')">
          <v-icon small>{{mdiPencil}}</v-icon>
          {{ item.status }}
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-if="selectedCurrency"
      v-model="dialogStatus.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Cambiar status del extra {{selectedCurrency.name}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormStatus"
            lazy-validation
          >
            <v-select
              v-model="selectedCurrency.status"
              :items="['active', 'inactive']"
              :rules="[v => !!v || 'El status es requerido']"
              label="Seleccione el status"
              required
            ></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogStatus.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormStatus"
            @click="updateStatus"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mdiClose, mdiPencil } from '@mdi/js';
  import Currency from '@/models/Currency';
   export default {
    name:'CategoriesExtras',
    data:() => {
      return {
        mdiPencil: mdiPencil,
        mdiClose:mdiClose,
        //////////////////////////////////////////
        currencyModel:new Currency,
        tableHeaders:[
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Codigo',
            align: 'start',
            sortable: false,
            value: 'code',
          },
          {
            text: 'Tipo de cambio',
            align: 'start',
            sortable: false,
            value: 'exchangeRate',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        search:'',
        selected:[],
        items:null,
        selectedCurrency:null,
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        dialogStatus:{
          show:false
        },
        validFormStatus:true
      }
    },
    methods:{
      setItem(item) {
        this.selectedCurrency = {... item};
        this.dialogStatus.show = true;
      },
      updateStatus() {
        let loading = this.$loading.show();
        this.currencyModel.update(this.selectedCurrency.id, {status:this.selectedCurrency.status}).then(() => {
          loading.hide();
          this.dialogStatus.show = false;
          this.snackbar.show = true;
          this.snackbar.color = 'green';
          this.snackbar.message = `El status se actualizo correctamente`;
        })
        .catch(error => {
          console.log(error);
          this.snackbar.show = true;
          this.snackbar.color = 'red';
          this.snackbar.message = `Error al actualizar el status, por favor contacte al Administrador si el problema persiste`;
        })
      }
    },
    mounted() {
      this.currencyModel.getAll().onSnapshot(snap => {
        this.items = [];
        snap.forEach(doc => {
          this.items.push(doc.data());
        })
      })
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    }
  }
</script>